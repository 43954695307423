@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --swiper-pagination-bullet-inactive-color: var(--tw-color-white);
    --swiper-pagination-bullet-inactive-opacity: 0.3;
    --swiper-theme-color: var(--tw-color-primary-400);
  }

  body {
    -webkit-text-size-adjust: 100%;
    font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    @apply bg-dark relative leading-relaxed text-slate-400;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }

  .xor-mask {
    &::before {
      mask: linear-gradient(black, black) content-box content-box,
      linear-gradient(black, black);
      mask-composite: xor;
      border-radius: inherit;
    }
  }

  .rich-text-container {
    ol,
    ul {
      padding-left: 22px;
      margin: 20px 0;
    }

    ul {
      list-style: disc;
    }

    ol {
      list-style: decimal-leading;
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    p:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .rendered-markdown {
    pre {
      margin: 30px 0;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  .section-heading {
    @apply text-3xl md:text-4xl lg:text-5xl;
  }
}
